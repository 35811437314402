import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BlogBarComponent } from './blog-bar/blog-bar.component';
import {MatChipsModule} from '@angular/material/chips';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    BlogBarComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatChipsModule,
    MatButtonModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    BlogBarComponent
  ],
})
export class SharedModule { }
