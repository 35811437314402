<div class="about-us-container">

    <div class="page-header-container">
        <div class="page-header-img"> </div>
        <div class="page-header-text-div">
            <div class="page-header-text">About Us</div>
        </div>
    </div>

    <div class="about-blurb-1-container bg-light">
        <div class="blurb-img-div-mobile">
            <img class="blurb-img-1-mobile elevated" src='/assets/img/stock/campus.jpg'>
        </div>
        <div class="blurb-wrapper">
            <div class="blurb-text-div">
                <div class="generic-header-text">
                    Who we are
                </div>
                <div class="title-spacer-left"></div>
                <div class="accent-body-text">
                    <p>
                        Hydronos Labs was founded in 2020 by Colby Fisher and Joe Studholme. Having previously worked on
                        a project together at Princeton University to develop and commercialize state-of-the-art global
                        hydrological modeling, data and analytics, we realized that the typically high cost of the major
                        commercial climate risk assessment platforms was a significant barrier to use for all but the
                        largest companies. We believe there is a critical need (and a market) for open access data
                        analysis platforms that enable smaller companies, state and local agencies, individuals,
                        researchers and nonprofits to effectively understand and mitigate their weather and climate
                        risks.
                        <br><br>
                        Our tools are built with open access and open science in mind to create trust and transparency.
                        The philosophy behind our system architecture has been to build a framework that has the
                        flexibility to allow the rapid adoption of new public data and to enhance results based on
                        ongoing improvements in scientific knowledge and data availability.
                        <br><br>

                    </p>
                </div>
            </div>
        </div>
        <div class="blurb-img-div">
            <img class="blurb-img-1 elevated" src='/assets/img/stock/campus.jpg'>
        </div>



    </div>

    <div class="about-blurb-2-container">
        <div class="blurb-2-img-div">
            <div class="blurb-img-2 elevated" src='/assets/img/stock/river.png'></div>
        </div>

        <div class="blurb-wrapper">
            <div class="blurb-text-div">
                <div class="generic-header-text">
                    What we do
                </div>
                <div class="title-spacer-left"></div>
                <div class="accent-body-text">
                    <p>
                        First and foremost, we are a team of scientists, engineers and software developers (A.K.A.
                        nerds) with domain expertise in global to regional climate modeling, hydrology and remote
                        sensing, focusing on natural hazard risk assessments and forecasts. We provide a full range of
                        services, ranging from exploratory research and data wrangling to building deeply integrated
                        systems for clients interested in understanding and mitigating the impacts of these risks on
                        their business. Our past work has included projects in diverse industries including agriculture,
                        insurance, international development, emergency management & financial services. Hydronos Labs
                        also works regularly with other early stage companies spun out of Princeton University research,
                        providing custom software design and development support, pilot programs and commercialization
                        services enabling better technology transfer between scientists and practitioners.
                        <br><br>
                        While we traditionally develop bespoke climate and weather risk assessment tools integrated
                        directly into client systems, we are ardent supporters of open science and open source
                        technology, building on the wealth of recent scientific advancements in weather and climate
                        risk. Since its inception, Hydronos Labs has served as a commercial partner for the National
                        Science Foundation Convergence Accelerator funded <a href="https://knowwheregraph.org"
                            target="_blank">KnowWhereGraph</a> project, providing domain expertise and software
                        development services. This project aims to build the first open geo-enabled knowledge graph
                        enabling easy access to a wealth of environmental information at a scale and efficiency not
                        currently available. Through a number of pilot projects, we’ve seen firsthand what this
                        technology can do for improving general situational awareness and decision-making processes. We
                        are busy adapting this technology to weather and climate risk assessments and are eager to make
                        it available for public use through our platform.

                    </p>
                </div>
            </div>
        </div>

    </div>

    <div class="about-goals-container bg-light">
        <div class="about-goals-header">
            <div class="generic-header-text">What we believe in </div>
        </div>
        <div class="title-spacer"></div>
        <div class="goals-wrapper">
            <div class="goals-container">
                <div class="goals-div">
                    <div class="goals-icon-wrapper">
                        <div class="goals-icon-container">
                            <div class="goals-icon-div">
                                <mat-icon class="goals-icon">window</mat-icon>
                            </div>
                            <div class="goals-icon-txt">Transparency</div>
                        </div>
                    </div>
                    <div class="goals-text-div accent-body-text">
                        No black box models or proprietary data sources. Transparency builds trust and promotes
                        widespread adoption.
                    </div>
                </div>
            </div>
            <div class="goals-container">
                <div class="goals-div">
                    <div class="goals-icon-wrapper">
                        <div class="goals-icon-container">
                            <div class="goals-icon-div">
                                <mat-icon class="goals-icon">hive</mat-icon>
                            </div>
                            <div class="goals-icon-txt">Collaboration</div>
                        </div>
                    </div>
                    <div class="goals-text-div accent-body-text">
                        Knowledge sharing and user feedback from scientists, practitioners and policymakers drives
                        continuous improvement.
                    </div>
                </div>
            </div>
            <div class="goals-container">
                <div class="goals-div">
                    <div class="goals-icon-wrapper">
                        <div class="goals-icon-container">
                            <div class="goals-icon-div">
                                <mat-icon class="goals-icon">integration_instructions</mat-icon>
                            </div>
                            <div class="goals-icon-txt">Accessibility</div>
                        </div>
                    </div>
                    <div class="goals-text-div accent-body-text">
                        Building on public-funded technology and datasets, we aim to always provide a complete product
                        for free use.
                    </div>
                </div>
            </div>

        </div>

    </div>


    <div class="faq-container">
        <div class="faq-header" id="faq-header">
            <div class="generic-header-text">FAQ</div>
        </div>
        <div class="title-spacer"></div>

        <div class="faq-wrapper">
            <mat-accordion [multi]="true">


                <mat-expansion-panel class="expansion-panel bg-light">
                    <mat-expansion-panel-header class="expansion-header bg-darker">
                        <mat-panel-title>
                            Why is open access important?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="expansion-body bg-light">
                        <ul class="faq-list">
                            <li class="faq-list-item">Trust: Openness ensures our methods are transparent to all key
                                actors, and therefore can be trusted by you, your stakeholders, and the broader
                                community. We hope this leads to widespread adoption and use. </li>
                            <li class="faq-list-item">Collaboration: By sharing knowledge and expertise, others can
                                learn from and apply our analysis, and push us to improve. Collaboration, skepticism,
                                and user feedback are the drivers of continuous improvement for Hydronos.
                            </li>
                            <li class="faq-list-item faq-list-last">Cost: We believe that it is important to permit free
                                or low-cost use of the basic underlying technology and datasets - generally developed at
                                public expense - to a broad global audience. Additional costs to users should be tied to
                                the commercial value created ‘downstream’ by analysis and integration.</li>
                        </ul>
                    </div>

                </mat-expansion-panel>


                <mat-expansion-panel class="expansion-panel bg-light">
                    <mat-expansion-panel-header class="expansion-header bg-darker">
                        <mat-panel-title>
                            Can I download and use the data?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="expansion-body bg-light">
                        Yes, the data we generate and provide are free and open to use for your own analysis as long as
                        your use complies with the licensing and restrictions of the source data providers and the <a
                            style="color: #007bff; text-decoration: none; cursor: pointer;"
                            (click)="this.goTo('terms')">Terms of Service.</a> We benefit greatly from a wealth of
                        public data sources and ask that you
                        adhere to the same terms of use.
                    </div>

                </mat-expansion-panel>



                <mat-expansion-panel class="expansion-panel bg-light">
                    <mat-expansion-panel-header class="expansion-header bg-darker">
                        <mat-panel-title>
                            Do you offer integration or customization services?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="expansion-body bg-light">
                        We work closely with clients to understand their priorities, gather supply chain data, build
                        custom indicators and metrics, integrate with existing systems, and interpret results. Often, we
                        collaborate on pilot projects to determine the value and scope of integration to a large
                        organization. To learn more about our services offered, <a (click)="this.goTo('services')"
                            style="color: #007bff; text-decoration: none; cursor: pointer;"> Click Here</a>

                    </div>

                </mat-expansion-panel>


                <mat-expansion-panel class="expansion-panel bg-light">
                    <mat-expansion-panel-header class="expansion-header bg-darker">
                        <mat-panel-title>
                            What is a knowledge graph? Is it related to AI?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="expansion-body bg-light">
                        A knowledge graph is essentially a network of interconnected nodes and edges representing
                        entities (things) and their relationships. In geospatial analysis, these entities can be
                        anything geographically relevant, like cities, rivers, landmarks, or even historical events, and
                        the edges represent their spatial connections or relationships. Building these graphs help us to
                        find hidden patterns, improve decisions, integrate data, explore knowledge, and visualize
                        information with other AI tools in ways that traditional data structures do not allow.
                        <br><br>
                        Hydronos Labs has been working with the <a href="https://knowwheregraph.org"
                            target="_blank">KnowWhereGraph</a> project and others on building a NSF
                        funded Open Knowledge Network, combining many domain specific knowledge graphs. This open
                        infrastructure created will enable open access to rich, structured data that is essential for
                        evolution and use of AI and AI-based methods. We are positioned to take advantage of the rapidly
                        developing OKN infrastructure and to make use of it in a wide variety of geospatial AI-related
                        applications.
                    </div>

                </mat-expansion-panel>

                <mat-expansion-panel class="expansion-panel bg-light">
                    <mat-expansion-panel-header class="expansion-header bg-darker">
                        <mat-panel-title>
                            What does your logo represent?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="expansion-body bg-light">
                        In a magical forest, there lives the <b>Hydrowl</b>, a mythical owl born from the tears of a
                        water nymph and the wisdom of ancient trees.
                        <br><br>
                        With feathers as blue as clear waters and eyes that sparkle like diamonds, the <b>Hydrowl</b> is
                        the guardian of nature's balance.
                        <br><br>
                        Residing behind a towering waterfall, the <b>Hydrowl</b> has the power to control water in all
                        its forms. It journeys across the land, blessing lakes and rivers, bringing life and harmony
                        wherever it goes.. However, it is not to be taken lightly; those who exploit nature face its
                        wrath.
                        <br><br>
                        Wise and approachable, the <b>Hydrowl</b> offers guidance to those with good intentions and
                        provides insights into the future of the land. A symbol of wisdom, balance and conservation, the
                        <b>Hydrowl</b>'s legend inspires all generations to respect and protect the delicate wonders of
                        the natural world that it tirelessly guards.
                        <br><br>
                        But really, we have no clue. This is just the logo we picked from our $50 logo design contest on
                        Freelancer.
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel class="expansion-panel bg-light">
                    <mat-expansion-panel-header class="expansion-header bg-darker">
                        <mat-panel-title>
                            Where can I send fan mail and/or gift baskets?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="expansion-body bg-light">
                        Hydronos Labs, LLC <br>
                        61 Princeton Hightstown Rd, STE 2B<br>
                        Princeton Junction, NJ 08550<br>
                    </div>

                </mat-expansion-panel>
                
                <mat-expansion-panel class="expansion-panel bg-light">
                    <mat-expansion-panel-header class="expansion-header bg-darker">
                        <mat-panel-title>
                            Do you have any job openings?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="expansion-body bg-light">
                        You can look at our employment opportunities 
                        <a target="_blank" style="color: #007bff; text-decoration: none; cursor: pointer;" href="https://www.indeed.com/cmp/Hydronos-Labs/jobs">here</a>

                    </div>

                </mat-expansion-panel>


                <mat-expansion-panel class="expansion-panel bg-light">
                    <mat-expansion-panel-header class="expansion-header bg-darker">
                        <mat-panel-title>
                            Whose dog is the best?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="expansion-body bg-light">
                        We ask ourselves this every day but, honestly, all of our dogs are equally good.
                        <div style="display: flex; padding-top:50px;">
                            <img style="width: 33%;" src="assets/img/rclose.jpg">
                            <img style="width: 33%;" src="assets/img/holly.jpg">
                            <img style="width: 33%;" src="assets/img/fishers.jpg">
                        </div>
                    </div>
                </mat-expansion-panel>



            </mat-accordion>
        </div>

    </div>

    <div id='contact-container-div' class="contact-container-div bg-light">
        <div class="faq-header" id="faq-header">
            <div class="generic-header-text">Contact Us</div>
        </div>
        <div class="title-spacer"></div>

        <div class="contact-text-div">
            <div class="center-contact-div">
                <div class="contact-div">
                    <div class="small-header-text">Address</div>
                    <div class="accent-body-text">
                        Hydronos Labs, LLC
                        <br>
                        61 Princeton Hightstown Rd, STE 2B
                        <br>
                        Princeton Junction, NJ 08550
                    </div>
                </div>
                <div class="contact-div">
                    <div class="small-header-text">Email</div>
                    <div class="accent-body-text">
                        <a href="mailto:info@hydronoslabs.com">info@hydronoslabs.com</a>
                    </div>
                    <br>
                    <div class="small-header-text">Phone</div>
                    <div class="accent-body-text">
                        <a href="tel:+16096816868">(609)-681-6868</a>
                    </div>
                </div>
            </div>


        </div>

    </div>

    <div class="logo-container">
        <div class="logo-text">
            <div class="generic-header-text">Partners</div>
        </div>
        <div class="logo-wrapper">
            <div class="logo-div-princeton">
                <img src="/assets/logos/princeton.png" class="logo-img">
            </div>
            <div class="logo-div">
                <img src="/assets/logos/ow.png" class="logo-img">
            </div>
            <div class="logo-div-nfs">
                <img src="/assets/logos/nfs.png" class="logo-img">
            </div>
            <div class="logo-div">
                <img src="/assets/logos/rt.png" class="logo-img">
            </div>
            <div class="logo-div">
                <img src="/assets/logos/ucsb.png" class="logo-img">
            </div>

        </div>
    </div>


</div>
<app-footer></app-footer>