import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component'
import { LoginComponent } from './login/login.component'
import { HomepageComponent } from './homepage/homepage.component'
import { HomeComponent } from './home/home.component';
import { BlogModule } from './blog/blog.module';
import { ServicePageComponent } from './service-page/service-page.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ProductsPageComponent } from './products-page/products-page.component';
import { ContactComponent } from './contact/contact.component';

const routes: Routes = [
  //{ path: '', component: HomepageComponent },
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'services', component: ServicePageComponent},
  { path: 'products', component: ProductsPageComponent},
  { path: 'about', component: AboutUsComponent},
  { path: 'insights', loadChildren: () => BlogModule},
  { path: 'terms', component: TermsComponent},
  { path: 'privacy', component: PrivacyComponent},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
