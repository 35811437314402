import { ViewportScroller } from '@angular/common';
import { HostListener, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  pageYoffset: number;
  @HostListener('window:scroll', ['$event']) onScroll(event) {
    this.pageYoffset = window.pageYOffset;
  }

  constructor(private router: Router, private scroll: ViewportScroller) { }
  scrollToElementById(id: string) {
    const element = this.__getElementById(id);
    this.scrollToElement(element);
  }



  private __getElementById(id: string): HTMLElement {
    const element = document.getElementById(id);
    return element;
  }
  scrollToElement(element: HTMLElement) {
    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
  }

  scrollToElementCenter(element: HTMLElement) {
    setTimeout(() => {
      element.scrollIntoView({ behavior: "smooth", block:'center'});
    }, 100);
    
  }

  scrollToElementStart(element: HTMLElement) {
    setTimeout(() => {
      element.scrollIntoView({ behavior: "smooth", block:'start'});
    }, 100);
    
  }
  scrollToTop(){
    setTimeout(() => {
      const element = this.__getElementById('top');
      this.scrollToElementCenter(element);
    }, 100);
    
  }

  scrollToFaq(){
    setTimeout(() => {
      const element = this.__getElementById('faq-header');
      this.scrollToElementStart(element);
    }, 100);
    
  }

  scrollToContact(){

    setTimeout(() => {
      const element = this.__getElementById('contact-container-div');
      this.scrollToElementStart(element);
    }, 200);
    
  }
  

}
