<div class="footer-container">
    <div class="footer-links-container">
        <div>
            <ul>
                <li (click)="this.goTo('products')">Products</li>
                <li (click)="this.goTo('services')">Services</li>
                <li (click)="this.goTo('insights')">Insights</li>
            </ul>
        </div>
        <div>
            <ul>
                <li (click)="this.goTo('about')">About Us</li>
                <li (click)="this.goToContact()">Contact Us</li>
                <li (click)="this.goToFaq()">FAQ</li>
                <li (click) = "this.goToCareers()">Careers</li>
            </ul>
        </div>
        <div>
            <ul>
                <li (click)="this.goTo('terms')">Terms</li>
                <li (click)="this.goTo('privacy')">Privacy</li>
            </ul>
        </div>
    </div>
    <div class="footer-copy">
        © Hydronos Labs, LLC 2023. All Rights Reserved.
    </div>
</div>