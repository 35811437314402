<nav class = "nav-bar" id = "nav-bar">
    <div class = "nav-logo" (click) = "this.goToHome()">
        <img src="/assets/logos/transparent_long.png" height="40px">
    </div>
    <div class = "filler"></div>
    <div class = "hamburger" (click) = "this.openDropdown = !this.openDropdown">
        <mat-icon>menu</mat-icon>
    </div>
    <div class = "nav-buttons" [ngClass]="{'nav-buttons-drop-down': openDropdown}">
        <div class = "nav-btn" (click)="this.goToProducts()" [class.active]='this.router.url.split("/")[1] == "products"'>Products</div>
        <div class = "nav-btn" (click)="this.goToServices()" [class.active]='this.router.url.split("/")[1] == "services"'>Services</div>
        <div class = "nav-btn" (click)="this.goToBlog()" [class.active]='this.router.url.split("/")[1] == "insights"'>Insights</div>
        <div class = "nav-btn" (click)="this.goToAboutUs()" [class.active]='this.router.url.split("/")[1] == "about"'>About Us</div>
        <div class = "nav-btn" (click)="this.sendToPlatform()">Sign In</div>
    </div>   
</nav>
