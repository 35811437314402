<div class="page-header-container">
    <div class="page-header-img"> </div>
    <!-- <div class="page-header-text-div">
        <div class="blog-header-text">{{this.blog.activeTitle}}</div>
    </div> -->
</div>
<div class="blog-post-container bg-light">
    <!-- <button mat-raised-button class = "bg-light">Read More</button> -->
    <markdown [src]="this.blog.activeFileName">
    </markdown>
</div>
<app-blog-bar></app-blog-bar>

<app-footer></app-footer>