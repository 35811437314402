import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ScrollService } from 'src/app/services/scroll.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  constructor(public router: Router, public scroll: ScrollService){

  }

  goTo(route:string){
    this.router.navigate([route]);
    this.scroll.scrollToTop();
  }

  goToFaq(){
    this.router.navigate(['about']);
    this.scroll.scrollToFaq()
  }

  goToContact(){
    this.router.navigate(['about']);
    // this.scroll.scrollToTop();
    this.scroll.scrollToContact();
  }

  goToCareers(){
    window.open('https://www.indeed.com/cmp/Hydronos-Labs/jobs', "_blank");
  }
}
