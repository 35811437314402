import { Component } from '@angular/core';
import { BlogService } from '../services/blog.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  constructor( public blog: BlogService) { }
  ngOnInit() {
    this.blog.pageTitle = "Featured Insights"
    this.blog.activeId = "";
  }

}
