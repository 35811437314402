import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-products-page',
  templateUrl: './products-page.component.html',
  styleUrls: ['./products-page.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ProductsPageComponent {
  imageObject: Array<object> = [{
    //image: 'assets/screenshots/supply1.png',
    thumbImage: 'assets/screenshots/supply-dark.png',
    //alt: 'alt of image',
    //title: 'title of image'
}, 
{
  //image: 'assets/screenshots/supply1.png',
  thumbImage: 'assets/screenshots/simple-api-2.png',
  //alt: 'alt of image',
  //title: 'title of image'
}, 
{
  //image: 'assets/screenshots/supply1.png',
  thumbImage: 'assets/screenshots/rules-dark.png',
  //alt: 'alt of image',
  //title: 'title of image'
}, 
{
  //image: 'assets/screenshots/supply1.png',
  thumbImage: 'assets/screenshots/crop-dark.png',
  //alt: 'alt of image',
  //title: 'title of image'
}, 
{
  //image: 'assets/screenshots/supply1.png',
  thumbImage: 'assets/screenshots/dist-dark.png',
  //alt: 'alt of image',
  //title: 'title of image'
}, 
{
  //image: 'assets/screenshots/supply1.png',
  thumbImage: 'assets/screenshots/simple-api-1.png',
  //alt: 'alt of image',
  //title: 'title of image'
}, 
{
  //image: 'assets/screenshots/supply1.png',
  thumbImage: 'assets/screenshots/temp-dark.png',
  //alt: 'alt of image',
  //title: 'title of image'
}, 
{
  //image: 'assets/screenshots/supply1.png',
  thumbImage: 'assets/screenshots/api-doc.png',
  //alt: 'alt of image',
  //title: 'title of image'
}, 
// {
//     image: '.../iOe/xHHf4nf8AE75h3j1x64ZmZ//Z==', // Support base64 image
//     thumbImage: '.../iOe/xHHf4nf8AE75h3j1x64ZmZ//Z==', // Support base64 image
//     title: 'Image title', //Optional: You can use this key if want to show image with title
//     alt: 'Image alt', //Optional: You can use this key if want to show image with alt
//     order: 1 //Optional: if you pass this key then slider images will be arrange according @input: slideOrderType
// }
];
}
