import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from 'src/app/services/theme.service';
import { ScrollService } from 'src/app/services/scroll.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  openDropdown:boolean = false;
  constructor( public router: Router, public theme: ThemeService, public scroll: ScrollService) {


  }

  goToHome() {
    
    this.router.navigate(['']);
    this.scroll.scrollToTop();
  }
  goToServices() {
    this.hideHamburger();  
    this.router.navigate(['services']);
    this.scroll.scrollToTop();
  }
  goToProducts(){
    this.hideHamburger();
    this.router.navigate(['products']);
    this.scroll.scrollToTop();

  }
  goToBlog() {
    this.hideHamburger();
    this.router.navigate(['insights']);
    this.scroll.scrollToTop();
  }
  goToAboutUs() {
    this.hideHamburger();
    this.router.navigate(['about']);
    this.scroll.scrollToTop();
  }

  sendToPlatform(){
    //window.location.href = "http://platform.hydronoslabs.com/"
    window.location.href = environment.platformUrl;
  }

  hideHamburger(){
    this.openDropdown = false;
  }
}
