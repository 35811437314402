import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import {firebase, firebaseui, FirebaseUIModule} from 'firebaseui-angular';
import {environment} from '../environments/environment';
// import {AngularFireModule} from '@angular/fire';
// import {AngularFireAuthModule, USE_EMULATOR as USE_AUTH_EMULATOR} from '@angular/fire/auth';
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoginComponent } from './login/login.component';
import { HomepageComponent } from './homepage/homepage.component';

import { HomeComponent } from './home/home.component';
import { SplashComponent } from './home-components/splash/splash.component';
import { LogoBarComponent } from './home-components/logo-bar/logo-bar.component';
// import { BlogBarComponent } from './shared/blog-bar/blog-bar.component';
import { HomePickerComponent } from './home-components/home-picker/home-picker.component';
import { BlurbAComponent } from './home-components/blurb-a/blurb-a.component';
import { BlurbBComponent } from './home-components/blurb-b/blurb-b.component';
import { BlurbCComponent } from './home-components/blurb-c/blurb-c.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import { ServicePageComponent } from './service-page/service-page.component';
import { SharedModule } from './shared/shared.module';
import { AboutUsComponent } from './about-us/about-us.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatChipsModule} from '@angular/material/chips';
import {MatButtonModule} from '@angular/material/button';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';

import { MarkdownModule } from 'ngx-markdown';
import 'prismjs';
import 'prismjs/components/prism-typescript.min.js';
import 'prismjs/plugins/line-numbers/prism-line-numbers.js';
import 'prismjs/plugins/line-highlight/prism-line-highlight.js';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ProductsPageComponent } from './products-page/products-page.component';
import { ContactComponent } from './contact/contact.component';
import { NgImageSliderModule } from 'ng-image-slider';


// const firebaseUiAuthConfig: firebaseui.auth.Config = {
//   signInFlow: 'popup',
//   signInOptions: [

//     {
//       requireDisplayName: false,
//       provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
//     },

//   ],
//   tosUrl: '<your-tos-link>',
//   privacyPolicyUrl: '<your-privacyPolicyUrl-link>',
//   credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
// };

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomepageComponent,
    HomeComponent,
    SplashComponent,
    LogoBarComponent,
    // BlogBarComponent,
    HomePickerComponent,
    BlurbAComponent,
    BlurbBComponent,
    BlurbCComponent,
    ServicePageComponent,
    AboutUsComponent,
    TermsComponent,
    PrivacyComponent,
    ProductsPageComponent,
    ContactComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    SharedModule,
    MatExpansionModule,
    MatChipsModule,
    MatButtonModule,
    HttpClientModule,
    MarkdownModule.forRoot({ loader: HttpClient }),
    NgImageSliderModule,

  ],

  providers: [
    // { provide: USE_AUTH_EMULATOR, useValue: !environment.authproduction ? ['localhost', 9099] : undefined },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
