<div class="products-page-container">

    <!-- <div class="page-header-container">
        <div class="page-header-img"> </div>
        <div class="page-header-text-div">
            <div class="page-header-text">Products</div>
        </div>
    </div> -->

    <div class="product-splash-container">
        <div class="product-splash-container-img">

        </div>
        <div class="product-splash-text-div">
            <div class="product-splash-text">
                TerraCognos integrates publicly available weather and climate data with state-of-the-art machine learning,
                knowledge graphs, and linked data techniques to provide comprehensive risk assessment, planning and
                mitigation capabilities. This is all provided through a no-code web based visualization platform,
                customized by the user to meet their specific needs.
            </div>
        </div>
    </div>

    <!-- <div class="products-diagram-container">
        <div class="products-diagram-img-div">
            <img src="assets/diagrams/products.png" class="products-diagram-img">
        </div>
    </div> -->

    <div class="products-diagram-container">
        <div class="diagram-wrapper">
            <div class="diagram-container">

                <div class="diagram-div bg-light elevated-border">
                    <div class="diagram-icon-wrapper">
                        <div class="diagram-icon-container">
                            <div class="diagram-icon-div">
                                <mat-icon class="diagram-icon">account_circle</mat-icon>
                            </div>
                            <div class="diagram-icon-txt">User Input</div>
                        </div>
                    </div>
                    <div class="diagram-text-div accent-body-text">
                        <ul class="diagram-list">
                            <li class="diagram-list-item">Secure Asset and Supply Chain Data Integration </li>
                            <li class="diagram-list-item">Customizable Rules, Triggers and Reporting</li>
                            <li class="diagram-list-item">Definable Asset Types and Links</li>
                        </ul>
                    </div>
                </div>

                <div class="diagram-arrow-div-right">
                    <div class="diagram-arrow-right">
                        <mat-icon class="diagram-arrow-right">arrow_right</mat-icon>
                    </div>
                </div>

            </div>
            <div class="diagram-container">
                <div class="diagram-arrow-div-down">
                    <div class="diagram-arrow-down">

                        <mat-icon class="diagram-arrow-down">arrow_drop_down</mat-icon>
                    </div>
                </div>
                <div class="diagram-div bg-light elevated-border">
                    <div class="diagram-icon-wrapper">
                        <div class="diagram-icon-container">
                            <div class="diagram-icon-div">
                                <img src="/assets/logos/hydronos_logo.png" class="diagram-owl-logo">
                            </div>
                            <div class="diagram-icon-txt">TerraCognos</div>
                        </div>
                    </div>
                    <div class="diagram-text-div accent-body-text">
                        <ul class="diagram-list">
                            <li class="diagram-list-item">Real-Time Weather Monitoring</li>
                            <li class="diagram-list-item">Predictive Analytics and Threat Detection</li>
                            <li class="diagram-list-item">Dynamic Risk Mapping </li>
                        </ul>
                    </div>
                </div>

                <div class="diagram-arrow-div-right">
                    <div class="diagram-arrow-right">
                        <mat-icon class="diagram-arrow-right">arrow_right</mat-icon>
                    </div>
                </div>

            </div>
            <div class="diagram-container">
                <div class="diagram-arrow-div-down">
                    <div class="diagram-arrow-down">
                        <mat-icon class="diagram-arrow-down">arrow_drop_down</mat-icon>
                    </div>
                </div>
                <div class="diagram-div bg-light elevated-border">
                    <div class="diagram-icon-wrapper">
                        <div class="diagram-icon-container">
                            <div class="diagram-icon-div">
                                <mat-icon class="diagram-icon">analytics</mat-icon>
                            </div>
                            <div class="diagram-icon-txt">Enhanced Decision Support</div>
                        </div>
                    </div>
                    <div class="diagram-text-div accent-body-text">
                        <ul class="diagram-list">
                            <li class="diagram-list-item">Automated Alerts</li>
                            <li class="diagram-list-item">Adaptive Strategy Triggers</li>
                            <li class="diagram-list-item">Open Access and Community Collaboration</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="image-slider-container bg-light">
        <div class="image-slider-div">
            <ng-image-slider style="width: 101%;" class="image-slider" [images]="imageObject" [infinite]="true"
                [autoSlide]="{interval: 3, stopOnHover: false}" [imageSize]="{width: '33%', height: '33%'}"
                [imagePopup]="false" [manageImageRatio]="true" [showArrow]="false" [animationSpeed]="2" #nav>
            </ng-image-slider>
        </div>
        <div class="image-slider-div-mobile">
            <ng-image-slider style="width: 101%;" class="image-slider" [images]="imageObject" [infinite]="true"
                [autoSlide]="{interval: 3, stopOnHover: true}" [imageSize]="{width: '100%', height: '33%'}"
                [imagePopup]="false" [manageImageRatio]="true" [showArrow]="false" [animationSpeed]="5" #nav>
            </ng-image-slider>
        </div>
    </div>


    <div class="key-features-container">
        <div class="key-features-header">
            <div class="generic-header-text">Key Features</div>
            <div class="products-title-spacer"></div>
        </div>


        <div class="service-bar-container">
            <div class="service-icon-container">
                <div class="service-icon-div">
                    <mat-icon class="service-icon">cloud</mat-icon>
                </div>
                <!-- <div class="service-icon-text">Real-Time Weather Monitoring and Predictive Analytics</div> -->
            </div>
            <div class="service-content-div">
                <div class="service-spacer"></div>
                <div class="service-text-div">
                    <div class="small-header-text">
                        Real-Time Weather Monitoring and Predictive Analytics:
                    </div>
                    <div class="service-text strong-body-text">
                        TerraCognos continuously monitors real-time weather and climate data from various sources, including
                        satellite imagery, weather models, and environmental sensors.

                        <br><br>
                        Leveraging advanced machine learning algorithms, the software analyzes historical weather
                        patterns and current meteorological data and forecasts to predict potential asset level impacts
                        or disruptions to supply chains. This includes forecasts of extreme weather events, such as
                        hurricanes, floods, or severe thunderstorms, with high accuracy from trusted public data
                        providers.
                    </div>
                </div>
            </div>
        </div>


        <div class="service-bar-container bg-light">
            <div class="service-icon-container">
                <div class="service-icon-div">
                    <mat-icon class="service-icon">link</mat-icon>
                </div>
                <!-- <div class="service-icon-text">Dynamic Risk Mapping</div> -->
            </div>
            <div class="service-content-div">
                <div class="service-spacer"></div>
                <div class="service-text-div">
                    <div class="small-header-text">
                        Dynamic Risk Mapping:
                    </div>
                    <div class="service-text strong-body-text">
                        A real-time dynamic risk map is generated to visualize asset locations or a supply chain
                        network. This allows users to identify high-risk areas and prioritize risk mitigation
                        strategies.
                    </div>
                </div>
            </div>
        </div>

        <div class="service-bar-container">
            <div class="service-icon-container">
                <div class="service-icon-div">
                    <mat-icon class="service-icon">tune</mat-icon>
                </div>
                <!-- <div class="service-icon-text">Dynamic Risk Mapping</div> -->
            </div>
            <div class="service-content-div">
                <div class="service-spacer"></div>
                <div class="service-text-div">
                    <div class="small-header-text">
                        Customized Impact Assessments:
                    </div>
                    <div class="service-text strong-body-text">
                        Our AI-based software assesses the potential impact of current and future weather disruptions on
                        any user-defined entity, region of interest or complex supply-chain. Users can control all
                        aspects of this reporting, customizing tolerances on a per hazard basis to meet their specific
                        business needs.
                    </div>
                </div>
            </div>
        </div>


        <div class="service-bar-container bg-light">
            <div class="service-icon-container">
                <div class="service-icon-div">
                    <mat-icon class="service-icon">notifications</mat-icon>
                </div>
                <!-- <div class="service-icon-text">Dynamic Risk Mapping</div> -->
            </div>
            <div class="service-content-div">
                <div class="service-spacer"></div>
                <div class="service-text-div">
                    <div class="small-header-text">
                        Automated Alerts and Decision Support:
                    </div>
                    <div class="service-text strong-body-text">
                        Users securely provide their own asset or supply chain data, allowing for automated alerting and
                        risk identification. Further decision support insights can be provided by suggesting adaptive
                        strategies to mitigate the impact of disruptions based on your tolerances. This might include
                        rerouting logistics, adjusting inventory levels, or activating contingency plans.
                    </div>
                </div>
            </div>
        </div>

        <div class="service-bar-container">
            <div class="service-icon-container">
                <div class="service-icon-div">
                    <mat-icon class="service-icon">lock_open</mat-icon>
                </div>
                <!-- <div class="service-icon-text">Dynamic Risk Mapping</div> -->
            </div>
            <div class="service-content-div">
                <div class="service-spacer"></div>
                <div class="service-text-div">
                    <div class="small-header-text">
                        Open Access and Community Collaboration:
                    </div>
                    <div class="service-text strong-body-text">
                        To promote transparency and accessibility, TerraCognos operates on an open-access platform. We
                        encourage collaboration within a global community, allowing users to contribute data, insights,
                        and improvement suggestions. We also welcome community contributions, extending the
                        functionality of the current platform.
                    </div>
                </div>
            </div>
        </div>


        <div class="service-bar-container bg-light">
            <div class="service-icon-container">
                <div class="service-icon-div">
                    <mat-icon class="service-icon">loop</mat-icon>
                </div>
                <!-- <div class="service-icon-text">Dynamic Risk Mapping</div> -->
            </div>
            <div class="service-content-div">
                <div class="service-spacer"></div>
                <div class="service-text-div">
                    <div class="small-header-text">
                        Continuous Improvement:
                    </div>
                    <div class="service-text strong-body-text">
                        TerraCognos includes a feedback loop mechanism, learning from real-world events and user feedback to
                        continuously improve predictive models and enhance the overall effectiveness of risk
                        assessments.
                    </div>
                </div>
            </div>

        </div>
        <div class="products-summary-div">
            <div class="products-summary-text-div">
                <div class="service-text strong-body-text">
                    By combining advanced technologies, open access principles, and collaborative features, TerraCognos
                    is a powerful tool for individuals or organizations seeking to proactively manage and mitigate
                    the impacts of weather and climate related disruptions.
                </div>
            </div>

        </div>

    </div>

    <div class="coming-soon-container">
        <div class="coming-soon-text">

            <div class="flex-center">

                <div class="small-header-text">
                    Coming Q2 2024
                </div>
            </div>

            <div class="strong-body-text">
                For updates contact us at <a href="mailto: info@hydronoslabs.com">info@hydronoslabs.com</a>
            </div>
        </div>
    </div>




    <!-- <div class="products-blurb-1-container bg-light">
        <div class="blurb-img-div-mobile">
            <img class="blurb-img-1-mobile" src='/assets/img/stock/team-awful.png'>
        </div>
        <div class="blurb-wrapper">
            <div class="blurb-text-div">
                <div class="generic-header-text">
                    Dumb Name
                </div>
                <div class="title-spacer-left"></div>
                <div class="accent-body-text">
                    <p>
                        [dumb name] integrates publicly available climate and weather data with state-of-the-art machine
                        learning, knowledge graphs, and linked data techniques to provide comprehensive assessment,
                        planning and risk mitigation capabilities. This is all provided through a no-code SaaS platform,
                        customized by the user to meet their specific needs.
                        <br><br>

                    </p>
                </div>
            </div>
        </div>
        <div class="blurb-img-div">
            <img class="blurb-img-1" src='/assets/img/stock/team-awful.png'>
        </div>
    </div> -->
    <!-- 
    <div class="key-features-header-container ">
        <div class="key-features-header">
            <div class="generic-header-text">Key Features</div>
        </div>
    </div>

    <div class="key-feature-div-left-img bg-light">

        <div class="key-feature-image-left-div">
            <img class="key-feature-image" src='/assets/img/stock/team-awful.png'>
        </div>

        <div class="key-feature-content-div">
            <div>
                <div class="small-header-text">
                    Real-Time Weather Monitoring and Predictive Analytics
                </div>

                <ul>
                    <li class="list-element strong-body-text">[Name] continuously monitors real-time climate and weather
                        data from various sources, including satellite imagery, weather stations, and environmental
                        sensors.
                    </li>
                    <li class="list-element strong-body-text">Leveraging advanced machine learning algorithms, the
                        software
                        analyzes historical weather patterns and current meteorological data and forecasts to predict
                        potential disruptions to supply chains. This includes forecasting extreme weather events, such
                        as
                        hurricanes, floods, and droughts, with high accuracy from trusted public data providers</li>
                </ul>
            </div>

        </div>

    </div>

    <div class="key-feature-div-right-img">

        <div class="key-feature-img-div-mobile">
            <img class="key-feature-image-mobile" src='/assets/img/stock/team-awful.png'>
        </div>


        <div class="key-feature-content-div">
            <div>
                <div class="small-header-text">
                    Dynamic Risk Mapping
                </div>

                <ul>
                    <li class="list-element strong-body-text">Using knowledge graph technology, [name] creates a dynamic
                        risk map that visualizes the supply chain network and associated locations in real-time. This
                        allows users to identify high-risk areas and prioritize risk mitigation strategies
                    </li>
                </ul>

                <div class="small-header-text">
                    Custom Impact Assessments
                </div>

                <ul>
                    <li class="list-element strong-body-text">The AI software assesses the potential impact of current
                        and future weather disruptions on any user-defined entity, region of interest or complex
                        supply-chain. Users can control all aspects of this reporting, customizing the results to meet
                        their specific business needs.
                    </li>
                </ul>
            </div>

        </div>

        <div class="key-feature-image-right-div">
            <img class="key-feature-image" src='/assets/img/stock/team-awful.png'>
        </div>

    </div>

    <div class="key-feature-div-left-img bg-light">

        <div class="key-feature-image-left-div">
            <img class="key-feature-image" src='/assets/img/stock/team-awful.png'>
        </div>

        <div class="key-feature-content-div">
            <div>
                <div class="small-header-text">
                    Automated Alerts and Decision Support:
                </div>

                <ul>
                    <li class="list-element strong-body-text">When users provide their own asset or supply chain data,
                        we generate automated alerting and decision support by suggesting adaptive strategies to
                        mitigate the impact of disruptions based on your tolerances. This might include rerouting
                        logistics, adjusting inventory levels, or activating contingency plans.
                    </li>
                </ul>
            </div>

        </div>

    </div>

    <div class="key-feature-div-right-img">

        <div class="key-feature-img-div-mobile">
            <img class="key-feature-image-mobile" src='/assets/img/stock/team-awful.png'>
        </div>


        <div class="key-feature-content-div">
            <div>
                <div class="small-header-text">
                    Open Access and Community Collaboration
                </div>

                <ul>
                    <li class="list-element strong-body-text">To promote transparency and accessibility, [name] operates
                        on an open-access platform. The software encourages collaboration within a global community,
                        allowing users to contribute data, insights, and improvement suggestions. We also welcome
                        community contributions, extending the functionality of the current platform.
                    </li>
                </ul>

                <div class="small-header-text">
                    Continuous Improvement through Feedback Loop:
                </div>

                <ul>
                    <li class="list-element strong-body-text">[name] includes a feedback loop mechanism, learning from
                        real-world events and user feedback to continuously improve predictive models and enhance the
                        overall effectiveness of risk assessments
                    </li>
                </ul>
            </div>

        </div>

        <div class="key-feature-image-right-div">
            <img class="key-feature-image" src='/assets/img/stock/team-awful.png'>
        </div>

    </div> -->



</div>

<app-footer></app-footer>