import { Component, OnInit } from '@angular/core';
// import { FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult } from 'firebaseui-angular';
// import { AngularFireAuth } from '@angular/fire/auth';
// import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
// import { AngularFireModule, FirebaseApp } from '@angular/fire'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  // constructor(public afAuth: AngularFireAuth, private db: AngularFireDatabase, private af: FirebaseApp) {

  // }

  ngOnInit(): void {
  }
  // successCallback(signInSuccessData: FirebaseUISignInSuccessWithAuthResult) {
  //   console.log(signInSuccessData)
  // }

  // errorCallback(errorData: FirebaseUISignInFailure) {
  //   console.log(errorData)
  // }

  // uiShownCallback(data) {
  //   console.log(data);
  // }


  // private firebaseAuthChangeListener(response) {
  //   // if needed, do a redirect in here
  //   if (response) {
  //     console.log('Logged in :)');
  //     console.log(response)
  //   } else {
  //     console.log('Logged out :(');
  //   }
  // }

}
