<!DOCTYPE html>
<html lang="en">


<body>

  <!-- Navigation -->
  <nav class="navbar navbar-light bg-light static-top fixed-top">
    <div class="container">
      <a class="navbar-brand" href="#"><img src="/assets/logos/transparent_long.png" height="40px"> </a>
    </div>
  </nav>

  <!-- Masthead -->
  <header class="masthead text-white text-center">
    <div class="overlay"></div>
    <div class="container" style = "font-size: 1rem !important;">
      <!-- <div class="row">
        <div class="col-xl-8 mx-auto"> -->
          <!-- <h3 class="mb-5">LOGIN</h3> -->
          <!-- <form> -->
            <!-- <div class="form-row">
              <div class="col-md-6 offset-md-3">
                <input type="email" class="form-control form-control-lg" placeholder="Email Address">
                <input type="password" class="form-control form-control-lg mt-2" placeholder="Password">
                <button class="btn btn-block btn-lg btn-teal mt-4">Login</button>
              </div>

            </div> -->
            <!-- <firebase-ui
            (signInSuccessWithAuthResult)="successCallback($event)"
            (signInFailure)="errorCallback($event)"
            (uiShown)="uiShownCallback($event)"></firebase-ui> -->
          <!-- </form> -->
        <!-- </div>
      </div> -->
    </div>
  </header>


  <!-- Footer -->
  <footer class="footer bg-light">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 h-100 text-center  my-auto">
          <!-- <ul class="list-inline mb-2">
            <li class="list-inline-item">
              <a href="#">About</a>
            </li>
            <li class="list-inline-item">&sdot;</li>
            <li class="list-inline-item">
              <a href="#">Contact</a>
            </li>
            <li class="list-inline-item">&sdot;</li>
            <li class="list-inline-item">
              <a href="#">Terms of Use</a>
            </li>
            <li class="list-inline-item">&sdot;</li>
            <li class="list-inline-item">
              <a href="#">Privacy Policy</a>
            </li>
          </ul> -->
          <p class="text-muted small mb-4 mb-lg-0">Contact Us - <a href="mailto:info@hydronoslabs.com">info@hydronoslabs.com</a></p>
          <p class="text-muted small mb-4 mb-lg-0">&copy; Hydronos Labs 2020. All Rights Reserved.</p>
        </div>
        <!-- <div class="col-lg-6 h-100 text-center text-lg-right my-auto">
          <ul class="list-inline mb-0">
            <li class="list-inline-item mr-3">
              <a href="#">
                <i class="fab fa-facebook fa-2x fa-fw"></i>
              </a>
            </li>
            <li class="list-inline-item mr-3">
              <a href="#">
                <i class="fab fa-twitter-square fa-2x fa-fw"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a href="#">
                <i class="fab fa-instagram fa-2x fa-fw"></i>
              </a>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
  </footer>

  <!-- Bootstrap core JavaScript -->
  <!-- <script src="vendor/jquery/jquery.min.js"></script>
  <script src="vendor/bootstrap/js/bootstrap.bundle.min.js"></script> -->

</body>

</html>
