export const environment = {
    production: false,
    authproduction:true,
    firebaseConfig:{
      apiKey: "AIzaSyBeonhzoyFY5KsE5wCq3fMgcQICzYbVNTw",
      authDomain: "hydronos-interface-dev.firebaseapp.com",
      databaseURL: "https://hydronos-interface-dev-default-rtdb.firebaseio.com/",
      projectID: "hydronos-interface-dev"
    },
    test:"dev environment",
    platformUrl:"https://dev-platform.hydronoslabs.com"
  };
  