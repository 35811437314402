import { Component } from '@angular/core';
import { BlogService } from 'src/app/services/blog.service';

@Component({
  selector: 'app-blog-bar',
  templateUrl: './blog-bar.component.html',
  styleUrls: ['./blog-bar.component.scss']
})
export class BlogBarComponent {
  constructor(public blog:BlogService) { }

}
