import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
  encapsulation: ViewEncapsulation.None, //enables css to be read from this component css file
})
export class TermsComponent {
  filename = "assets/markdown/terms.md"
}
