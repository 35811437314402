<div class="blurb-container" id="productsDiv">
    <!-- <div class="img-1-mobile">

    </div> -->
    <div class="img-bar-div-mobile">
        <img src="/assets/img/cube.jpg" class="bar-img-mobile elevated">
    </div>
    <div class="blurb-text-wrapper">
        <div class="blurb-text-div">
            <div class="generic-header-text">
                Products
            </div>
            <div class="title-spacer-left"></div>
            <div class="accent-body-text">
                Hyronos's TerraCognos is an open access web platform leveraging public weather and climate data with
                knowledge graph technology to generate advanced insight for individuals and organizations of all sizes.
                By combining real-time monitoring, predictive analytics, and dynamic risk mapping with features
                including automated decision support, alerting, and collaborative planning, anyone can proactively
                assess the risks of extreme weather events on their property, assets or supply chains.
            </div>
        </div>
        <div class="blurb-more-div">
            <button mat-raised-button class="bg-light" (click)="this.goTo('products')">Learn More</button>
        </div>

    </div>
    <!-- <div class="img-1">

    </div> -->
    <div class="img-bar-div">
        <img src="/assets/img/cube.jpg" class="bar-img elevated">
    </div>
</div>