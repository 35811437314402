export const THEMES: any = {
    light: {
        mainBackground: 'rgb(247 247 247)',
        secondBackground: 'rgb(217 217 217)',
        thirdBackground: 'rgb(239 239 239)',
        fourthBackground: 'rgb(220 220 220)',
        hoverColor: 'rgb(162 162 162)',
        primaryText: 'black',
        // accentText: 'rgb(18 171 193)',
        accentText: 'rgb(23 181 189)',
        darkAccentText:'rgb(23 142 160)',
        h1Size: '3rem',
        h2Size: '1.75rem',
        h3Size: '1rem',
    },
    // dark: {
    //     mainBackground: 'rgb(49 49 49)',
    //     secondBackground: 'rgb(75 75 75)',
    //     thirdBackground: 'rgb(105 105 105)',
    //     fourthBackground: 'rgb(140,140,140)',
    //     hoverColor: 'rgb(174 174 174)',
    //     primaryText: 'white',
    //     accentText: 'rgb(18 171 193)',
    // }
};



export const SIZETHEMES: any  = {
    Handset:{
        xlText:'2rem',
        lText:'1.1rem',
        medText:'1rem',
        text:'.8rem',
        smallText:'.7rem',
        assetSmallTableHeight:'90%'
    },
    XSmall:{
        xlText:'2rem',
        lText:'1.1rem',
        medText:'1rem',
        text:'.8rem',
        smallText:'.7rem',
        assetSmallTableHeight:'90%'
    },
    Small:{
        xlText:'2rem',
        lText:'1.1rem',
        medText:'1rem',
        text:'.8rem',
        smallText:'.7rem',
        assetSmallTableHeight:'90%'
    },
    Medium:{
        xlText:'2rem',
        lText:'1.1rem',
        medText:'1rem',
        text:'.8rem',
        smallText:'.7rem',
        assetSmallTableHeight:'90%'
    },
    Large:{
        xlText:'3rem',
        lText:'1.8rem',
        medText:'1.2rem',
        text:'.9rem',
        smallText:'.8rem',
        assetSmallTableHeight:'90%'
    },
    XLarge:{
        xlText:'5rem',
        lText:'2rem',
        medText:'1.5rem',
        text:'1rem',
        smallText:'.9rem',
        assetSmallTableHeight:'70%'
    }
};