<div class="page-header-container">
    <div class="page-header-img"> </div>
    <div class="page-header-text-div">
        <div class="page-header-text">Terms and Conditions</div>
    </div>
</div>
<div class = "terms-container bg-light">

    <markdown [src] = "this.filename"> 
    </markdown>  
</div>
<app-footer></app-footer>
 