<div class = "blog-main-container bg-light">
    
    <div class="page-header-container">
        <div class="page-header-img"> </div>
        <div class="page-header-text-div">
            <div class="page-header-text">Insights</div>
        </div>
    </div>


    <div *ngIf = "this.blog.sortedMetaData" class = "blog-wrapper">
        <div class = "blog-container">
            <div (click)="this.blog.goToBlogById(entry.value.id)"
            *ngFor="let entry of this.blog.sortedMetaData | keyvalue" class="blog-box"
            >
            <div class="blog-img-div">
                <img class="blog-img" src="{{entry.value.image}}">
            </div>
            <div class="blog-body">
                <div class="blog-title">
                    <div class="blog-title-text">
                        {{entry.value.title}}
                    </div>
                    <div class="blog-spacer"></div>
                </div>
                <div class="blog-blurb body-text-small">{{entry.value.blurb}}</div>
                <div class = "blog-more-div">
                    <button mat-raised-button class = "bg-light">Read More</button>
                </div>
                <div class="blog-footer">
                    <div class="blog-tags">
                        <mat-chip-list >
                            <mat-chip class = "blog-tag" [disabled] = 'true' *ngFor="let tag of entry.value.tags">
                                {{tag}}
                            </mat-chip>
                        </mat-chip-list>
                    </div>
                    <div class="blog-date">{{entry.value.date}}</div>
                </div>
            </div>
        </div>
        </div>

    </div>
</div>

<app-footer></app-footer>
