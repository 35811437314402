import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ScrollService } from '../services/scroll.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AboutUsComponent {
  constructor(public router: Router, public scroll: ScrollService){

  }

  goTo(route:string){
    this.router.navigate([route]);
    this.scroll.scrollToTop();
  }
}
