import { Injectable } from '@angular/core';
import blogMetaData from '../../assets/blog-metadata.json';
import { Router } from '@angular/router';
import { ScrollService } from './scroll.service';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  blogMetaData = blogMetaData;
  sortedMetaData = [];
  activeTitle;
  activeId;
  activeFileName;
  pageTitle;
  constructor(public router: Router, public scroll: ScrollService) {
    
    //sorting into date desc
    const entries = Object.entries(this.blogMetaData);
    entries.sort((a: any, b: any) => {
      return Date.parse(b[1].date) - Date.parse(a[1].date);

    });
    entries.forEach((entry:any)=>{
      this.sortedMetaData.push(entry[1])
    })
  }

  goToBlogById(id: string) {
    //this.activeTitle = this.blogMetaData[id].title;
    this.activeFileName = 'assets/markdown/' + this.blogMetaData[id].filename;
    this.pageTitle = "Other Insights"
    this.activeId = id;
    this.scroll.scrollToTop();
    this.router.navigate(['insights/' + id]);
  }


}
