
<div class="logo-container">
    <div class="logo-text">
        <div class = "generic-header-text">Partners</div>
    </div>
    <div class="logo-wrapper">
        <div class="logo-div-princeton">
            <img src="/assets/logos/princeton.png" class="logo-img">
        </div>
        <div class="logo-div">
            <img src="/assets/logos/ow.png" class="logo-img">
        </div>
        <div class="logo-div-nfs">
            <img src="/assets/logos/nfs.png" class="logo-img">
        </div>
        <div class="logo-div">
            <img src="/assets/logos/rt.png" class="logo-img">
        </div>
        <div class="logo-div">
            <img src="/assets/logos/ucsb.png" class="logo-img">
        </div>

    </div>
</div>