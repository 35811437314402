import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ScrollService } from 'src/app/services/scroll.service';

@Component({
  selector: 'app-blurb-b',
  templateUrl: './blurb-b.component.html',
  styleUrls: ['./blurb-b.component.scss']
})
export class BlurbBComponent {
  constructor(public router: Router, public scroll: ScrollService){

  }

  goTo(route:string){
    this.router.navigate([route]);
    this.scroll.scrollToTop();
  }
}
