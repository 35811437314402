<div class="service-container">


    <div class="page-header-container">
        <div class="page-header-img"> </div>
        <div class="page-header-text-div">
            <div class="page-header-text">Services</div>
        </div>
    </div>

    <div class="service-bar-container">
        <div class="service-icon-container">
            <div class="service-icon-div">
                <mat-icon class="service-icon">psychology</mat-icon>
            </div>
            <div class="service-icon-text">Domain Expertise</div>
        </div>
        <div class="service-content-div">
            <div class="service-spacer"></div>
            <div class="service-text-div">
                <div class="small-header-text">

                </div>

                <div class="service-text strong-body-text">
                    The team at Hydronos is highly skilled and experienced in working with commercial partners on
                    projects to prove value in climate risk transfer markets - insurance, finance, agriculture and
                    government. Domain specific skills include remote sensing and hydrology, global large scale
                    hydrological and climate modeling, and translation and integration of research to commercial uses
                    through efficient software development.
                </div>
            </div>

        </div>
    </div>



    <div class="service-bar-container bg-light ">
        <div class="service-icon-container">
            <div class="service-icon-div">
                <mat-icon class="service-icon">precision_manufacturing</mat-icon>
            </div>
            <div class="service-icon-text">Design-Build</div>
        </div>
        <div class="service-content-div">
            <div class="service-spacer"></div>
            <div class="service-text-div">
                <div class="small-header-text">

                </div>

                <div class="service-text strong-body-text">
                    <div >
                        Hydronos Labs also provides software design/build services for a wide variety of applications. Our
                        work includes:
                    </div>
                    <br><br>
                    <ul>
                        <li>Deployment and maintenance of sophisticated data platforms on a variety of hosts (e.g.,
                            Google, AWS, and On-Premises)</li>
                        <li>Design and deployment of appropriate databases, APIs and custom tooling</li>
                        <li>Design of application UI/UX and creation of web interfaces </li>
                        <li>Development and organization of complex source code libraries, including construction,
                            testing, debugging, and deployment of legacy applications and APIs</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="service-bar-container ">
        <div class="service-icon-container">
            <div class="service-icon-div">
                <mat-icon class="service-icon">currency_exchange</mat-icon>
            </div>
            <div class="service-icon-text">Commercialization</div>
        </div>
        <div class="service-content-div">
            <div class="service-spacer"></div>
            <div class="service-text-div">
                <div class="small-header-text">

                </div>

                <div class="service-text strong-body-text">
                    We are experts in the early stages of commercializing funded research. We work extensively with
                    research data and high-performance academic computing environments. Additionally, we provide
                    expertise with software and data IP issues, including identification of software and tools that
                    require commercial license, assessment of the commercial prospects of a project and guidance on key
                    decisions on licensing and patents.
                </div>
            </div>

        </div>
    </div>

    <div class="coming-soon-container">
        <div class="coming-soon-text">
            <div class="flex-center">
                <div class="small-header-text">
                    Contact us at <a href="mailto: info@hydronoslabs.com">info@hydronoslabs.com</a> to hear about our
                    custom build services
                </div>
            </div>
        </div>
    </div>

</div>
<app-footer></app-footer>