<div class="home-picker-container bg-light">
    <div class="home-picker-icon-wrapper">
        <div class="picker-container" (click) = "this.scroll.scrollToElementById('productsDiv')">
            <div class="picker-icon-div">
                <mat-icon class="picker-icon">conveyor_belt</mat-icon>
            </div>
            <div class="picker-text">
                Products
            </div>
        </div>
    </div>
    <div class="home-picker-icon-wrapper">
        <div class="picker-container" (click) = "this.scroll.scrollToElementById('servicesDiv')">
            <div class="picker-icon-div">
                <mat-icon class="picker-icon">miscellaneous_services</mat-icon>
            </div>
            <div class="picker-text">
                Services
            </div>
        </div>
    </div>
    <div class="home-picker-icon-wrapper">
        <div class="picker-container" (click) = "this.scroll.scrollToElementById('blogDiv')">
            <div class="picker-icon-div">
                <mat-icon class="picker-icon">library_books</mat-icon>
            </div>
            <div class="picker-text">
                Insights
            </div>

        </div>
    </div>
</div>