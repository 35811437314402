import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogService } from 'src/app/services/blog.service';

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.scss'],
  encapsulation: ViewEncapsulation.None, //enables css to be read from this component css file
})
export class BlogPostComponent {
  filename: string;
  title: string;
  constructor(private route: ActivatedRoute, public blog: BlogService) { }
  ngOnInit() {
    let id = this.route.snapshot.params.id;
    this.blog.activeTitle = this.blog.blogMetaData[id].title;
    this.blog.activeFileName = 'assets/markdown/' + this.blog.blogMetaData[id].filename;
    this.blog.activeId = id;
    this.blog.pageTitle = 'Other Insights'
  }



}
