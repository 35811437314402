import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
  encapsulation: ViewEncapsulation.None, //enables css to be read from this component css file

})
export class PrivacyComponent {
  filename = "assets/markdown/privacy.md"
}
