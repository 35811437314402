<div class="blurb-container" id="servicesDiv">
    <div class="img-bar-div-left">
        <img src="/assets/img/stock/satellite.jpg" class="bar-img-left elevated">
    </div>
    <div class="blurb-text-wrapper">
        <div class="blurb-text-div">
            <div class="generic-header-text">
                Services
            </div>
            <div class="title-spacer-left"></div>
            <div class="accent-body-text">
                We provide bespoke solutions, enabling our global customers to use the latest weather and climate models
                and data to enhance existing systems and workflows. We work across diverse industries including
                Agriculture, Insurance, Energy & Financial Services.
            </div>
        </div>

        <div class="blurb-more-div">
            <button mat-raised-button class="bg-light" (click)="this.goTo('services')">Learn More</button>
        </div>

    </div>
</div>