import { Component } from '@angular/core';
import { BlogService } from 'src/app/services/blog.service';

@Component({
  selector: 'app-blog-home',
  templateUrl: './blog-home.component.html',
  styleUrls: ['./blog-home.component.scss']
})
export class BlogHomeComponent {
  constructor(public blog:BlogService) { }
  ngOnInit() {
    //console.log(this.blog.blogMetaData);
    // Object.entries(this.blog.blogMetaData).forEach((entry) => {
    //   const [key, value] = entry;
    //   // console.log(`${key}: ${value}`);
    //   // console.log(value)
    // });

  }
}
